import {Link} from "react-router-dom";
import { PhoneIcon } from '@heroicons/react/outline';
import CTAImage from '../assets/helpdesk.jpg';

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

const secondaryCTA = () => {
  return (
    <section className="es-secondaryCTA relative">
      <figure className="hidden lg:block es-secondaryCTA__image-container">
        <img src={CTAImage} alt="" className="es-secondaryCTA__image" />
      </figure>
      <div className="relative lg:absolute w-full inset-0 flex items-center">
        <div className="container py-16 grid lg:grid-cols-2 lg:gap-x-14 mx-auto">
          <div className="lg:col-start-2">
            <h5 className="es-title--small mb-3">Need anything</h5>
            <h2 className="es-heading--two font-medium">
              We are more than happy to <br className="hidden md:block" />
              help you.
            </h2>

            <div className="flex flex-wrap gap-y-4 mt-8 items-center gap-x-3 lg:gap-x-5">
              <div>
                <Link
                  to="/contact"
                  className="btn-primary--small lg:btn-primary inline-block"
                >
                  Send us an enquiry
                </Link>
              </div>
              <p className="text-sm text-ebony font-semibold">OR</p>
              <div className="hidden lg:block">
                <p className="text-sm text-stormGray mb-1 font-semibold">
                  Call us at
                </p>
                <p className="text-ebony font-medium">+977 9851024094</p>
              </div>

              <a
                href="tel://+9779851024094"
                className="btn-secondary--call flex gap-x-2 text-center lg:hidden"
              >
                <PhoneIcon className="w-5 h-5 text-stormGray" />
                <span className="text-ebony font-medium">+977 9851024094</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default secondaryCTA;
