import { useState, useEffect } from "react";
import { useGetBrandsQuery } from "../services/productsApi";

const BrandList = () => {
  const [brandList, setBrandList] = useState([]);
  const { data } = useGetBrandsQuery();

  useEffect(() => {
    if (data)
      setBrandList(data.filter((item) => item.name !== "Eversafe Nepal"));
  }, [data]);

  return (
    <>
      <section className="es-section">
        <div className="container">
          <p className="es-title--small mb-3">OUR BRANDS</p>
          <h3 className="es-heading--three font-medium">
            Some of our <span className="text-primary">reputed</span>
            <br />
            <span className="text-primary">brands</span> include
          </h3>
        </div>

        <div className="container mt-8 md:mt-12 grid grid-cols-2 gap-x-8 md:flex md:flex-row md:flex-wrap md:gap-x-14 gap-y-16">
          {brandList.map((item) => (
            <div className="flex items-center" key={item.name}>
              <img
                src={"https://api.eversafe.com.np" + item.logo.url}
                alt={item.name}
                className="es-brand-logo"
              />
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default BrandList;
