import {Link} from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from '@heroicons/react/outline';
import LogoWhite from '../assets/logo_white.png';
import SiteCredit from './siteCredit';

const footer = () => {
  return (
    <footer className="es-footer">
      <div className="es-footer__top gap-y-10">
        <div className="col-span-12 lg:col-span-5">
          <img src={LogoWhite} alt="logo" className="es-footer__logo" />

          <div className="mt-8 text-gray-400">
            <p className="mb-3">Our business will be open</p>
            <p className="mb-2">Sunday - Friday</p>
            <p>10AM to 6PM</p>
          </div>
        </div>
        <div className="col-span-12 gap-y-10 grid md:grid-cols-3 lg:col-span-7 mt-4 lg:flex lg:justify-between">
          <div className="flex flex-col gap-y-5">
            {/* Quick Links */}
            <h4 className="es-footer__title">Quick Links</h4>
            <Link to="/" className="es-footer__link" onClick={()=>{
              window.scroll(0,0);
            }}>
              Home
            </Link>
            <Link to="/about" onClick={()=>{
              window.scroll(0,0);
            }} className="es-footer__link">
              About Us
            </Link>
            <Link to="/products" onClick={()=>{
              window.scroll(0,0);
            }} className="es-footer__link">
              Products
            </Link>
            <Link to="/contact" onClick={()=>{
              window.scroll(0,0);
            }} className="es-footer__link">
              Contact Us
            </Link>
          </div>

          {/* Our Offices */}
          <div className="flex flex-col gap-y-5">
            <h4 className="es-footer__title">Our Offices</h4>
            <div className="es-footer__icon-content">
              <LocationMarkerIcon className="w-5 h-5 mt-0.5" />
              <p className="leading-relaxed">
                Ravibhawan, <br /> Kathmandu, Nepal
              </p>
            </div>

            <div className="es-footer__icon-content items-center">
              <PhoneIcon className="w-5 h-5" />
              <p className="leading-relaxed">+977-1-5234630, 5234631, 5234632</p>
            </div>

            <div style={{cursor:'pointer'}} className="es-footer__icon-content items-center" onClick={()=>{
              window.location.href = `mailto:info.eversafe@gmail.com`;
            }}>
              <MailIcon className="w-5 h-5" />
              <p className="leading-relaxed">info.eversafenepal@gmail.com</p>
            </div>
          </div>

          {/* Connect with us */}
          <div className="flex flex-col gap-y-5">
            <h4 className="es-footer__title">Connect With Us</h4>
            <div className="es-footer__social">
              <a href="https://www.facebook.com/eversafenepal" target="_blank" rel="noreferrer" className="es-footer__social__link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="es-footer__social__icon"
                >
                  <path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h8.615v-6.96h-2.338v-2.725h2.338v-2c0-2.325 1.42-3.592 3.5-3.592.699-.002 1.399.034 2.095.107v2.42h-1.435c-1.128 0-1.348.538-1.348 1.325v1.735h2.697l-.35 2.725h-2.348V21H20a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z"></path>
                </svg>
              </a>
              <ReactWhatsapp number="+977-9851024094" message="" >
                  <div className="es-footer__social__link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="es-footer__social__icon"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"
                      ></path>
                    </svg>
                  </div>
              </ReactWhatsapp>
             
            </div>
          </div>
        </div>
      </div>
      <div className="es-footer__bot">
        <p>&copy; 2021 Eversafe Nepal Pvt. Ltd. All rights reserved.</p>
        <p>
          <SiteCredit />
        </p>
      </div>
    </footer>
  );
};
export default footer;
