import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactWhatsapp from "react-whatsapp";
import { removeAll, removeProduct } from "../services/enquirySlice";
import { useSubmitDataMutation } from "../services/productsApi";
import {
  validateEmail,
  validateName,
  validatePhone,
} from "../utils/validations";

const { XIcon } = require("@heroicons/react/outline");

const Contact = () => {
  const dispatch = useDispatch();
  const enquiryList = useSelector((state) => state.enquiryProduct.products);
  const [message, setMessage] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [errorData, setErrorData] = useState("");
  const [successData, setSuccessData] = useState("");

  const [
    queryProduct, // This is the mutation trigger
    { isLoading, error, isSuccess }, // This is the destructured mutation result
  ] = useSubmitDataMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setSuccessData(
        "Thank you for the enquiry. We will get back to you soon."
      );
      setEmail("");
      setPhoneNo("");
      setName("");
      setMessage("");
      dispatch(removeAll());
    }
  }, [isSuccess, dispatch]);

  useEffect(() => {
    setTimeout(() => setSuccessData(""), 4000);
  }, [successData]);

  useEffect(() => {
    if (error) setErrorData("Something went wrong! Please try again!");
  }, [error]);

  const onSend = () => {
    setErrorData("");
    if (!isLoading) {
      if (name.length === 0) {
        setErrorData("Name is required field");
      } else if (name.length <= 2) {
        setErrorData("Name must be more than 2 character");
      } else if (!validateName(name)) {
        setErrorData("Name is invalid");
      } else if (phoneNo.length > 0 && !validatePhone(phoneNo)) {
        setErrorData("Phone number is invalid.");
      } else if (email.length <= 0 && !validateEmail(email)) {
        setErrorData("Email is invalid");
      } else if (message.length > 0 && message.length <= 2) {
        setErrorData("Message must be more than 2 character");
      } else {
        let products = [];
        enquiryList.forEach((data) => products.push(data.id));

        const data = { full_name: name, email, products };

        if (message.length > 0) data["message"] = message;

        if (phoneNo.length > 0) data["phone"] = phoneNo;

        queryProduct(data);
      }
    }
  };

  return (
    <div className="pt-16 lg:pt-20">
      {/*
       * form and contact info
       */}
      <section className="es-section--small mt-12 lg:mt-24">
        <div className="container grid gap-y-8 md:grid-cols-6 lg:grid-cols-12">
          <div className="md:col-span-4 md:col-start-2 lg:col-span-5 lg:col-start-2">
            <p className="es-title--small mb-3">Contact Us</p>
            <h3 className="es-heading--three font-medium">
              Feel free to contact us, <br />
              we will get back to you soon.
            </h3>

            {/*
             * form
             */}
            <div className="mt-12 grid gap-y-10">
              {enquiryList.length > 0 && (
                <div>
                  <p className="es-form__label">Enquired Products</p>

                  <div className="grid gap-y-6 mt-5">
                    {enquiryList.map((item) => (
                      <div className="es-card--horizontal" key={item.id}>
                        <figure className="es-card__image-container">
                          <img
                            src={"https://api.eversafe.com.np" + item.image.url}
                            alt={item.name}
                            className="es-card__image"
                          />
                        </figure>
                        <div className="flex-1">
                          <h3 className="es-card__title">{item.name}</h3>
                        </div>
                        <div
                          style={{ display: "cursor" }}
                          className="inline-block p-1 text-gray-400 transition-colors duration-150 hover:text-ebony"
                        >
                          <XIcon
                            className="h-4 w-4"
                            onClick={() => {
                              dispatch(removeProduct(item.id));
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="grid gap-y-5">
                <div className="grid grid-cols-2 gap-x-5">
                  <div>
                    <label className="es-form__label" htmlFor="name">
                      Name
                    </label>
                    <input
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      type="text"
                      id="name"
                      className="es-form__input"
                    />
                  </div>
                  <div>
                    <label className="es-form__label" htmlFor="phone">
                      Phone
                    </label>
                    <input
                      type="text"
                      value={phoneNo}
                      onChange={(e) => {
                        setPhoneNo(e.target.value);
                      }}
                      id="phone"
                      className="es-form__input"
                      maxLength={10}
                    />
                  </div>
                </div>
                <div>
                  <label className="es-form__label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    id="email"
                    className="es-form__input"
                  />
                </div>

                <div>
                  <label className="es-form__label" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows="5"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    className="es-form__input"
                  />
                </div>

                {errorData !== "" && (
                  <p className="text-red-600">{errorData}</p>
                )}

                {successData !== "" && (
                  <div className="alert-primary px-5 py-3 leading-6">
                    {successData}
                  </div>
                )}

                <div className="flex justify-end">
                  <button
                    onClick={onSend}
                    className="btn-primary--small lg:btn-primary inline-block"
                    type="button"
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: 6 }}
                      />
                    )}
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="md:col-span-4 md:col-start-2 lg:col-span-3 lg:col-start-8 flex flex-col gap-y-12 mt-8">
            <div>
              <h5 className="es-title--small is-black mb-3">Opening Hours</h5>
              <p className="es-text text-darkGray mb-1">Sunday - Friday</p>
              <p className="es-text text-darkGray">10AM to 6PM</p>
            </div>

            <div>
              <h5 className="es-title--small is-black mb-3">
                for all enquiries
              </h5>
              <p className="es-text text-darkGray mb-1">
                +977-1-5234630, 5234631, 5234632
              </p>
              <p className="es-text text-darkGray">
                info.eversafenepal@gmail.com,
              </p>
              <p className="es-text text-darkGray">
                adhikari.eversafe@gmail.com
              </p>
            </div>

            <div>
              <h5 className="es-title--small is-black mb-3">Location</h5>
              <p className="es-text text-darkGray mb-1">
                Ravibhawan, <br />
                Kathmandu, 44608
              </p>
              <a
                href="https://www.google.com/maps/place/Eversafe+Nepal+Pvt+Ltd/@27.6959907,85.2912264,17.5z/data=!4m9!1m2!2m1!1seversafe+nepal+pvt+ltd!3m5!1s0x39eb19b4c24f505f:0x892465e43a207be3!8m2!3d27.6959722!4d85.2905407!15sChZldmVyc2FmZSBuZXBhbCBwdnQgbHRkkgEZc2FmZXR5X2VxdWlwbWVudF9zdXBwbGllcg"
                target="_blank"
                rel="noreferrer"
                className="es-btn-underline mt-1.5"
              >
                View on Map
              </a>
            </div>

            <div>
              <h5 className="es-title--small is-black mb-4">
                Connect with us on
              </h5>
              <div className="es-contact__social">
                <a
                  href="https://www.facebook.com/eversafenepal"
                  target="_blank"
                  rel="noreferrer"
                  className="es-contact__social__link"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="es-contact__social__icon"
                  >
                    <path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h8.615v-6.96h-2.338v-2.725h2.338v-2c0-2.325 1.42-3.592 3.5-3.592.699-.002 1.399.034 2.095.107v2.42h-1.435c-1.128 0-1.348.538-1.348 1.325v1.735h2.697l-.35 2.725h-2.348V21H20a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z"></path>
                  </svg>
                </a>
                <ReactWhatsapp number="+977-9851024094" message="">
                  <div href="#" className="es-contact__social__link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="es-contact__social__icon"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"
                      ></path>
                    </svg>
                  </div>
                </ReactWhatsapp>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Contact;
