import { useState, useEffect } from "react";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Link } from "react-router-dom";
import { useGetCategoryProductsQuery } from "../services/productsApi";

const ProductList = ({ id }) => {
  const [relatedProduct, setRelatedProduct] = useState([]);
  const { data, isLoading } = useGetCategoryProductsQuery(id);

  useEffect(() => {
    if (data) {
      setRelatedProduct(data);
    }
  }, [data]);

  if (isLoading)
    return (
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-7 gap-y-8">
        <div className="es-card--product">
          <RectShape color="#E0E0E0" style={{ paddingTop: "110%" }} />
        </div>
        <div className="es-card--product">
          <RectShape color="#E0E0E0" style={{ paddingTop: "110%" }} />
        </div>
        <div className="es-card--product">
          <RectShape color="#E0E0E0" style={{ paddingTop: "110%" }} />
        </div>
      </div>
    );

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-7 gap-y-8">
      {relatedProduct.map((item) => (
        <div className="es-card--product" key={item.id}>
          <Link
            to={`/product/id=${item.id},name=${item.name}`}
            className="es-card__link"
          >
            {item.image && (
              <figure className="es-card__image-container">
                <img
                  className="es-card__image"
                  src={"https://api.eversafe.com.np" + item.image.url}
                  alt={item.image.caption}
                />
              </figure>
            )}

            <div className="es-card__body">
              <h3 className="es-card__title">{item.name}</h3>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ProductList;
