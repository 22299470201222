import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PhoneIcon } from "@heroicons/react/outline";
import SecondaryCTA from "../components/secondaryCTA";
import ImportIcon from "../assets/services_icon/import.svg";
import DistributeIcon from "../assets/services_icon/distribution.svg";
import InstallIcon from "../assets/services_icon/installation.svg";
import MaintainIcon from "../assets/services_icon/maintenance.svg";
import ConsultIcon from "../assets/services_icon/consult.svg";
import TrainIcon from "../assets/services_icon/training.svg";
import RequestIcon from "../assets/services_icon/request.svg";
import { useGetCategoriesQuery } from "../services/categoryApi";
import BrandList from "../components/brandList";

const services = [
  {
    name: "Import",
    description:
      "We have been importing quality goods that meet not only the industry standards but the also needs of Nepali market for years.",
    icon: ImportIcon,
  },
  {
    name: "Distribution",
    description:
      "Our in-site warehouses and network of like-minded companies ensure a smooth delivery of products.",
    icon: DistributeIcon,
  },
  {
    name: "Installation",
    description:
      "Our industry trained and certified professionals make sure you get a detailed and thorough installation of your systems.",
    icon: InstallIcon,
  },
  {
    name: "Maintenance",
    description:
      "We provide a detailed inspection and support, warranties and remote and in-site maintenance of all services.",
    icon: MaintainIcon,
  },
  {
    name: "Consultation",
    description:
      "Not sure about what your needs are? We are more than happy to talk to. Just give us a call or drop us an email.",
    icon: ConsultIcon,
  },
  {
    name: "Training",
    description:
      "We also hold and provide training programmes, talks, workshop classes, demonstration, seminars and others.",
    icon: TrainIcon,
  },
  {
    name: "Your Request",
    description:
      "Not sure what you are looking for? We have a wide range services from office supplies to industry concerns.",
    icon: RequestIcon,
  },
];

const Home = () => {
  const { data } = useGetCategoriesQuery();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (data) setCategories(data.slice(0, 4));
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-16 lg:pt-20">
      <section className="es-hero gap-y-10 lg:gap-y-0">
        <div className="container grid pb-14 lg:pb-0 lg:grid-cols-2 gap-x-5">
          <div className="mb-6 lg:mb-20">
            <h1 className="es-heading--one">
              We <span className="text-primary">Serve</span>{" "}
              <br className="hidden md:block" />
              to keep you <span className="text-primary">Safe</span>
            </h1>

            <p className="es-text--lg mt-5 lg:mt-7 text-stormGray">
              We provide a wide range of products and{" "}
              <br className="hidden md:block" />
              services for your safety and security needs.
            </p>

            <div className="flex flex-wrap gap-y-4 mt-6 lg:mt-11 items-center gap-x-3 lg:gap-x-5">
              <div>
                <Link
                  to="/products"
                  className="btn-primary--small lg:btn-primary inline-block"
                >
                  View Products
                </Link>
              </div>
              <p className="text-sm text-ebony font-semibold">OR</p>
              <div className="hidden lg:block">
                <p className="text-sm text-gray-400 mb-1 font-semibold">
                  Call us at
                </p>
                <p className="text-ebony font-medium">+977 9851024094</p>
              </div>

              <a
                className="btn-secondary--call flex gap-x-2 text-center lg:hidden"
                href="tel://+9779851024094"
              >
                <PhoneIcon className="w-5 h-5 text-stormGray" />
                <span className="text-ebony font-medium">+977 9851024094</span>
              </a>
            </div>
          </div>
        </div>

        <figure className="es-hero__image-container">
          <img
            className="es-hero__image"
            src="https://images.unsplash.com/photo-1569436078135-61753b84e3e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2414&q=80"
            alt="hero"
          />
        </figure>
      </section>

      {/*
       * Block Content
       */}
      <section className="es-section--bg bg-offwhite">
        <div className="container">
          <h3 className="es-heading--three es-block-content--large">
            One stop solution. We import on our own, have our own sales and
            service team to make sure you get what you need under one roof. And
            we have an experience of more than{" "}
            <span className="text-primary">20 years</span> in this field.
          </h3>
        </div>
      </section>

      {/*
       * Products
       */}
      <section className="es-section">
        <div className="container grid grid-cols-4 gap-x-6 gap-y-8 lg:gap-x-10 lg:gap-y-10">
          <div className="col-span-4">
            <p className="es-title--small mb-3">Products</p>
            <h3 className="es-heading--three font-medium">
              We offer a <span className="text-primary">wide variety</span>{" "}
              <br />
              of products
            </h3>
          </div>

          {categories.map((item) => (
            <div className="col-span-2 lg:col-span-1" key={item.id}>
              <div className="es-card">
                <Link
                  to={`products/${item.id},${item.name}`}
                  className="es-card__link"
                >
                  <figure className="es-card__image-container">
                    <img
                      src={"https://api.eversafe.com.np" + item.image.url}
                      alt={item.image.caption}
                      className="es-card__image"
                    />
                  </figure>
                  <div className="es-card__body">
                    <h3 className="es-card__title">{item.name}</h3>
                  </div>
                </Link>
              </div>
            </div>
          ))}

          <div className="col-span-4 mt-4 flex justify-center">
            <Link
              to="/products"
              className="btn-primary--small lg:btn-primary inline-block"
            >
              View All Products
            </Link>
          </div>
        </div>
      </section>

      {/*
       * Services
       */}
      <section className="es-section">
        <div className="container">
          <p className="es-title--small mb-3">SERVICES</p>
          <h3 className="es-heading--three font-medium">
            Our services include but is
            <br />
            <span className="text-primary">not limited</span> to
          </h3>
        </div>

        <div className="container mt-8 lg:mt-12 grid grid-cols-4 gap-x-6 gap-y-8 lg:gap-x-10 lg:gap-y-10">
          {services.map((item) => (
            <div className="col-span-2 lg:col-span-1" key={item.name}>
              <div className="es-card">
                <img
                  src={item.icon}
                  alt={item.name}
                  className="es-card__icon mb-6 lg:mb-8"
                />
                <div className="es-card__body">
                  <h3 className="es-card__title">{item.name}</h3>
                  <p className="es-card__description">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <BrandList />

      <SecondaryCTA />
    </div>
  );
};
export default Home;
