import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const RelatedProducts = ({ relatedProd }) => {
  const [relatedProduct, setRelatedProduct] = useState([]);

  useEffect(() => {
    if (relatedProd) setRelatedProduct(relatedProd);
  }, [relatedProd]);

  return relatedProduct.length ? (
    <section className="es-section container grid gap-y-8 md:grid-cols-2 md:gap-x-10 md:gap-y-10 lg:grid-cols-4 lg:gap-x-7">
      {/*
       * Related Product
       */}
      <div className="md:col-span-2 lg:col-span-4">
        <h3 className="es-heading--three has-line font-bold md:mb-0 lg:mb-12">
          Related Products
        </h3>
      </div>

      {relatedProduct.map((item) => (
        <div className="es-card--product" key={item.id}>
          <Link
            to={`/product/id=${item.id},name=${item.name}`}
            className="es-card__link"
          >
            <figure className="es-card__image-container">
              {item.image && (
                <img
                  className="es-card__image"
                  src={"https://api.eversafe.com.np" + item.image.url}
                  alt={item.name}
                />
              )}
            </figure>

            <div className="es-card__body">
              <h3 className="es-card__title">{item.name}</h3>
            </div>
          </Link>
        </div>
      ))}
    </section>
  ) : (
    <></>
  );
};

export default RelatedProducts;
