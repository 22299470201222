import { useEffect } from 'react';
import SecondaryCTA from '../components/secondaryCTA';
import DirectorImage from '../assets/director.png';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className="pt-16 lg:pt-20">
      {/*
       * About us
       */}
      <section className="es-section--small">
        <div className="es-about__hero">
          <h1 className="es-heading--one font-semibold">
            <span className="text-primary">About</span> Us
          </h1>

          <p className="es-about__hero__text">
            We’re a safety company helping to secure Nepal since 2001 by
            providing wide range of products.
          </p>
        </div>
      </section>

      {/*
       * Team Image
       */}
      <section className="es-section--small container">
        <figure className="es-about__team-image-container">
          <img
            src="https://images.unsplash.com/photo-1598863505577-74750d3b4475?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80"
            alt="team"
            className="es-about__team-image"
          />
        </figure>
      </section>

      {/*
       * About us details
       */}
      <section className="es-section">
        <div className="container">
          <div className="es-about__details">
            <p className="es-text--xl">
              Keeping you safe is our job, and with our{' '}
              <span className="text-primary">years of experience</span>, we
              assure you <span className="text-primary">excellence</span>.
            </p>
            <p className="es-text text-stormGray">
              Ever since the company was established in 2001, it has been a
              leading and established distributor in the field of Safety and Security, Disaster Management, Rescue products and services.
            </p>
            <p className="es-text text-stormGray">
              Our products range from Fire Safety and Security, Surveillance
              (CCTV) systems to Official and Industrial items. We offer these
              products with wide variation for selection of quality and
              quantity.
            </p>
            <p className="es-text text-stormGray">
              Our suppliers are world class manufacturers/companies in their own
              right and been selected on basis of international standards and
              consistencies
            </p>
          </div>
        </div>
      </section>

      {/*
       * Vision
       */}
      <section className="es-section--bg bg-primary">
        <div className="container">
          <div className="es-block-content--large">
            <h5 className="es-title--small--light mb-3">Our Vision</h5>
            <h3 className="es-heading--three--light">
              We want to create a manufacturing and sales industry here in Nepal
              utilizing our local resources to create an internal ecosystem of
              safety and security services.
            </h3>
          </div>
        </div>
      </section>

      {/*
       * Message from the director
       */}
      <section className="es-section">
        <div className="container grid md:grid-cols-12 gap-x-5 items-center">
          <div className="md:col-span-4">
            <figure className="es-about__director__image-container">
              <img
                src={DirectorImage}
                alt="director"
                className="es-about__director__image"
              />
            </figure>
          </div>

          <div className="md:col-span-7 md:col-start-6">
            <div className="es-about__director__quote">
              <p>
                We are a diverse and experienced team with a shared purpose of
                providing quality service to our customers. Together, we are
                Eversafe. Thank you for taking the time to learn about us.
              </p>
              <p>I am glad you are here</p>
            </div>

            <div className="mt-8 mb-10 ml-9 md:ml-0">
              <h4 className="es-about__director__name">B.B. Adhikari</h4>
              <p className="es-about__director__title">Managing Director</p>
            </div>
          </div>
        </div>
      </section>

      {/*
       * Values
       */}
      <section className="es-section--bg es-section--no-margin bg-offwhite">
        <div className="container">
          <div className="es-block-content--large">
            <h5 className="es-title--small mb-3">Our Values</h5>
            <h3 className="es-heading--three">
              Ever since our establishment, we have always operated and
              developed with one core concept at the heart of the company. We
              value customer satisfaction above all else.
            </h3>
          </div>
        </div>
      </section>

      <SecondaryCTA />
    </div>
  );
};
export default About;
