import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductList from "../components/productList";
import { useGetCategoriesQuery } from "../services/categoryApi";

const Products = () => {
  const { id } = useParams();
  const [categoryId, setCategoryId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { data } = useGetCategoriesQuery();
  const [sideNav, setSideNav] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      setSideNav(data);
      const index = data.findIndex((cat) => cat.id.toString() === categoryId);
      if (index >= 0) {
        setSelectedCategory(data[index]);
      } else {
        setSelectedCategory(data[0]);
      }
    }
  }, [data, categoryId]);

  useEffect(() => {
    if (id) {
      const mySubString = id.substring(
        id.indexOf("=") + 1,
        id.lastIndexOf(",")
      );
      setCategoryId(mySubString);
    } else {
      setCategoryId("1");
    }
  }, [id]);

  return (
    <div className="pt-16 lg:pt-20">
      {/*
       * Breadcrumbs
       */}
      <nav className="container">
        <ul className="es-breadcrumbs">
          <li className="es-breadcrumbs__item">
            <div className="es-breadcrumbs__link">Products</div>
          </li>
          <li className="es-breadcrumbs__item">
            <div
              // href="#"
              className="es-breadcrumbs__link es-breadcrumbs__link--active"
            >
              {selectedCategory != null ? selectedCategory.name : <div></div>}
            </div>
          </li>
        </ul>
      </nav>

      <section className="es-section container flex flex-col-reverse gap-y-14 md:grid md:grid-cols-4 md:gap-x-10">
        {/*
         * Side nav
         */}
        <nav className="es-sidenav">
          <ul className="es-sidenav__list">
            <li className="es-sidenav__title">Categories</li>
            {sideNav.map((item) => (
              <li className="es-sidenav__item" key={item.id}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCategoryId(item.id.toString());
                    const index = data.findIndex(
                      (cat) => cat.id.toString() === item.id.toString()
                    );
                    if (index >= 0) {
                      setSelectedCategory(data[index]);
                    } else {
                      setSelectedCategory(data[0]);
                    }
                    window.history.pushState(
                      {},
                      null,
                      `/products/id=${item.id},name=${item.name}`
                    );
                  }}
                  className={`es-sidenav__link ${
                    item.id.toString() === categoryId
                      ? "es-sidenav__link--active"
                      : ""
                  }`}
                >
                  {item.name}
                </div>
              </li>
            ))}
          </ul>
        </nav>

        {/*
         * Product list
         */}
        {selectedCategory != null ? (
          <div className="col-span-3">
            <h3 className="es-heading--three font-bold mb-6 md:mb-8">
              {selectedCategory.name}
            </h3>
            <ProductList id={categoryId} />
          </div>
        ) : (
          <div></div>
        )}
      </section>
    </div>
  );
};
export default Products;
