import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const createRequest = (url) => ({url});

export const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_API_URL}),
    endpoints:(builder) => ({
        getCategories: builder.query({
            query: () => createRequest('/categories'),
        }),
    }),
});

export const {useGetCategoriesQuery} = categoryApi;
