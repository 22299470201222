import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { TextBlock, RectShape } from "react-placeholder/lib/placeholders";
import "react-placeholder/lib/reactPlaceholder.css";
import ReactMarkdown from "react-markdown";
import {
  useGetProductQuery,
  useGetRelatedProductQuery,
} from "../services/productsApi";
import RelatedProducts from "../components/relatedProducts";
import { useSelector, useDispatch } from "react-redux";
import { addProduct } from "../services/enquirySlice";

const ProductDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const enquiryList = useSelector((state) => state.enquiryProduct.products);
  const { id } = useParams();
  const [productId, setProductId] = useState("");
  const [productDetail, setProductDetail] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const [readMoreDesc, setReadMoreDesc] = useState(true);
  const [showReadMore, setShowReadMore] = useState(false);
  const { data: product = {} } = useGetProductQuery(productId);
  const { data: relProd = [] } = useGetRelatedProductQuery(productId);

  useEffect(() => {
    if (id) {
      const mySubString = id.substring(
        id.indexOf("=") + 1,
        id.lastIndexOf(",")
      );
      setProductId(mySubString);
    }
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (product) {
      setProductDetail(product);
      document.title = `Eversafe Nepal | ${product.name}`;
      if (product.description && product.description.length > 132) {
        setShowReadMore(true);
      }
    }
  }, [product]);

  return productDetail != null ? (
    <div className="pt-16 lg:pt-20">
      {/*
       * Breadcrumbs
       */}
      <nav className="container">
        <ul className="es-breadcrumbs">
          <li className="es-breadcrumbs__item">
            <div className="es-breadcrumbs__link">Products</div>
          </li>
          <li className="es-breadcrumbs__item">
            {productDetail != null && productDetail.category ? (
              <Link
                to={`/products/id=${productDetail.category.id},name=${productDetail.category.name}`}
                className="es-breadcrumbs__link"
              >
                {productDetail.category.name}
              </Link>
            ) : (
              <></>
            )}
          </li>
          <li className="es-breadcrumbs__item">
            <div className="es-breadcrumbs__link es-breadcrumbs__link--active">
              {productDetail.name}
            </div>
          </li>
        </ul>
      </nav>

      {/*
       * Product Hero
       */}
      <section className="es-section--small">
        <div className="container grid gap-y-8 lg:grid-cols-12 gap-x-14">
          <div className="lg:col-span-7">
            <figure className="es-product__image-container">
              {productDetail.image && (
                <img
                  className="es-product__image"
                  src={"https://api.eversafe.com.np" + productDetail.image.url}
                  alt="fire extinguisher"
                />
              )}
            </figure>
          </div>
          <div className="lg:col-span-4 flex items-center">
            <div className="es-product__content">
              <h4 className="es-title--small mb-1">{productDetail.brand}</h4>
              <h2 className="es-heading--two text-stormGray font-bold mb-4">
                {productDetail.name}
              </h2>
              <div className="es-text mb-8">
                {productDetail.description && (
                  <ReactMarkdown
                    children={
                      readMoreDesc
                        ? productDetail.description.substring(0, 132) + "..."
                        : productDetail.description
                    }
                  />
                )}

                {showReadMore && (
                  <span>
                    <a
                      href={() => false}
                      className="es-link--more mt-2 inline-block"
                      onClick={() => {
                        setReadMoreDesc(!readMoreDesc);
                      }}
                    >
                      {readMoreDesc ? "Read more" : "Read less"}
                    </a>
                  </span>
                )}
              </div>
              <div className="flex flex-wrap gap-y-4 gap-x-5 mb-8">
                {productDetail != null &&
                productDetail.catalog &&
                productDetail.catalog.length > 0 ? (
                  <a
                    href={
                      "https://api.eversafe.com.np" +
                      productDetail.catalog[0].url
                    }
                    rel="noreferrer"
                    target="_blank"
                    className="btn-secondary"
                  >
                    View Catalogue
                  </a>
                ) : (
                  <></>
                )}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsAdded(true);
                    const index = enquiryList.findIndex(
                      (data) => data.id === productDetail.id
                    );
                    if (index < 0) {
                      dispatch(addProduct(productDetail));
                    }
                    setInterval(() => {
                      setIsAdded(false);
                    }, 2000);
                  }}
                  className="btn-primary"
                >
                  {isAdded ? "Added to Enquiry List" : "Add to Enquiry List"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*
       * Technical Specification
       */}
      <section className="es-section">
        <div className="container">
          <h3 className="es-heading--three has-line font-bold mb-8">
            Technical Specification
          </h3>
        </div>

        <div className="container">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full overflow-hidden">
              {/* <ReactMarkdown children={productDetail.specification}  /> */}
              <div
                className="es-table__container"
                dangerouslySetInnerHTML={{
                  __html: productDetail.specification,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <RelatedProducts relatedProd={relProd} />
    </div>
  ) : (
    <div>
      <section className="es-section--small">
        <div style={{ height: "150px" }}></div>
        <div className="container grid gap-y-8 lg:grid-cols-12 gap-x-14">
          <div className="lg:col-span-7">
            <RectShape color="#b8bab9" />
          </div>
          <div className="lg:col-span-4 flex items-center">
            <TextBlock rows={20} color="#b8bab9" />
          </div>
        </div>
      </section>
    </div>
  );
};
export default ProductDetail;
