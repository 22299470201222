import {createSlice } from '@reduxjs/toolkit';

const initialState = {
    products:[],
}

export const productSlice = createSlice({
    name:"enquiryProduct",
    initialState,
    reducers:{
        addProduct:(state, action)=>{
            state.products = [...state.products,action.payload]
            localStorage.setItem("enquiryList",JSON.stringify(state.products));
        },
        initializeProduct:(state,action)=>{
            state.products = action.payload;
        },
        removeProduct:(state, action) =>{
            let temp = [...state.products];
            let productTemp = temp.filter((prod)=> prod.id !== action.payload);
            state.products = productTemp;
            localStorage.setItem("enquiryList",JSON.stringify(state.products));
        },
        removeAll:(state)=>{
            state.products =[]
            localStorage.removeItem("enquiryList");
        }
    }
});

export const {addProduct, removeProduct, removeAll, initializeProduct} = productSlice.actions;

export default productSlice.reducer;